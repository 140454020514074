const headersToJson = (root, tag) => {
    return Array.from(root.querySelectorAll(tag)).reduce((acc, header,index) => {
        acc.push({
            id:index,
            innerHTML : header.innerHTML,
        });
        return acc;
    },[]);
};

const toMenuItems = (itemData, liClass, liAnchorClass) => {
    const item = document.createElement('li');
    const anchor = document.createElement('a');
    
    anchor.setAttribute('href', `#header_${itemData.id}`);
    anchor.innerHTML = itemData['innerHTML'];
    anchor.className = liAnchorClass;
    
    item.className = liClass;
    item.appendChild(anchor);
    
    return item;
};

const mutateHeader = (root, tag) => {
    return Array.from(root.querySelectorAll(tag)).forEach((header ,index) => {
        header.id = `header_${index}`;
    });
};

const defaultSettings = {
    rootElement: null, // Mandatory
    navContainer : null, // Mandatory
    liClass : 'article__nav-list-item',
    liAnchorClass : 'article__nav-anchor'
};

export default function(args={}){
    
    const settings = Object.assign({}, defaultSettings, args);
    
    if(settings.rootElement && settings.navContainer){
        
        const {
            rootElement, 
            navContainer,
            liClass,
            liAnchorClass
        } = settings;
        
        const headerData = headersToJson(rootElement,'h2');
        const mutatedListOfHeaders = mutateHeader(rootElement,'h2');
        
        const headerContainer = document.createElement('div');
        
        const listOfMenuItems = headerData.map(itemData => {
            return toMenuItems(itemData, liClass, liAnchorClass);
        });
        
        
        // TODO: slow dom mutation, improve later
        listOfMenuItems.forEach(liElement => {
            navContainer.appendChild(liElement);
        });
    }else{
        console.warn('Mandatory arguments are null or undefined. articleNavCreator wont run');
        return false;
    }
}