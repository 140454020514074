/* global $ sspModules google */

//Polyfill
import "babel-polyfill";

// Styles include
import "bootstrap";
import "./style/style.scss";
// import "./assets/budicon2/Outline/style.css";
import "./style/ssp/map/fa-markers.css";
import "./style/ssp/map/font-awesome.css";
import "./scripts/fancybox/jquery.fancybox.css";
import "./style/ssp/map/ssp-map.scss";
import "../node_modules/leaflet/dist/leaflet.css";
import '../node_modules/leaflet.markercluster/dist/MarkerCluster.css';
import '../node_modules/leaflet.markercluster/dist/MarkerCluster.Default.css';

//Scripts include
import Sticky from 'stickyfilljs';
//import mainMenu from './scripts/mainMenu'; 
import articleNavCreator from './scripts/articleNavCreator';
//import articleBodyStylist from './scripts/articleBodyStylist';

// import Dotdotdot from "dotdotdot-js";


document.addEventListener('DOMContentLoaded', function(){
    $( window ).resize(function() {
        if($(window).width() < 768){
            $(".header__translatebox").detach().appendTo(".google-translate--mobile");
            $(".header__translatebox").removeAttr("data-parent");
        }else if($(window).width() >= 768 ){
            $(".header__translatebox").detach().appendTo(".google-translate");
            $(".header__translatebox").attr("data-parent", "#accordion")
        }
            
    });
    if($(window).width() < 768){
        $(".header__translatebox").detach().appendTo(".google-translate--mobile");
        $(".header__translatebox").removeAttr("data-parent");
    }
    sspModules.map({
    	map_container : $("[data-mapinit]"),
        force_fit_pois: true,
    	start_location: [59.405058, 8.533027],
    	dragging: true,
        mapOptions: {
            tap: false
        }
    });
    if($("#searchPage").length){
        // $("#searchPage ul").addClass("list-unstyled");
        $("#Q").addClass("rounded-0 form-control search__input");
        var input = $("#Q").parent().html();
        $('#searchBox > ul').replaceWith( '<div class="input-group mb-5">'+ input + '<button id="searchSubmitMain" class="search__submit-button rounded-0  btn ml-1 mb-0" type="submit" aria-label="search-button"><span class="material-icons align-top" aria-hidden="true">search</span></button> </div>' );
        $("#searchResult h2").text("Antall treff: ")
        $("#searchResult h2").addClass("no-sections");
    }
    
    sspModules.articleNav({
        articleHeader: $(".article-content h2:not(.timeline__section__level):not(.no-sections)"),
        navElements: $(".article-content h2:not(.timeline__section__level):not(.no-sections), .article-content h3.submenu"),
        affixTopDistance:".article-content",
        manual_bottom_offset : 1257,
        autoGotoSection : true,
        wrapClass:'article__body',
        aClass:'article__nav-anchor border-bottom-0 list-group-item list-group-item-action',
        liClass:'article__nav-list-item',
        articleheaderClass:'article__header'
    });
    
    if(!$(".basic-article-menu article h2").length){
        $(".basic-article-menu .article__nav ").remove();
        $(".basic-article-menu .article-heading").removeClass("offset-md-4").addClass("offset-md-2");
        $(".basic-article-menu article").removeClass("offset-md-1").addClass("offset-md-2");
    }
    
    // Customized article nav
    // articleNavCreator({
    //     rootElement: document.querySelector('.article__container'),
    //     navContainer : document.querySelector('.article__nav-list'),
    //     liClass : 'article__nav-list-item',
    //     liAnchorClass : 'article__nav-anchor'
    // });
    
    // articleBodyStylist({
    //     rootElement: document.querySelector('.article__container'),
    //     headerTag : 'h3',
    //     headerClass : 'article__header article__header--white article__header--partial-with-body',
    //     bodyClass : 'article__body article__body--white article__body--partial-with-header'
    // });
    
    sspModules.imageCompression({
        // addon_settings : ",f_auto,q_auto",
    });
    
    $('article img:not(.leaflet-tile-container > img)').each(function() {
        if (!$(this).hasClass("skip-gallery")) {
            $(this).addClass("img-gallery");
        }
    });
    if($("article .article__body").length){
        sspModules.createGallery({
            findImg: ".img-gallery",
            eachContainer: $("article .article__body"),
            minImageCount: 1,
        });
        
    }else{
        sspModules.createGallery({
            findImg: ".img-gallery",
            eachContainer: $("article .article__container")
        }); 
    }
    
    
    sspModules.createCollapse({
         nextuntil: "h1,h2,h3,h4,h5,h6,div,.stopaccor",
         imageReload : true,
         linkClass: 'article__collapse-link',
         contentClass :'article__collapse',
         openClass : 'article__collapse--open',
         linkOpenClass:'article__collapse-link--open'
    });
    

    
    // Position sticky polyfill
    if(document.querySelector('.article__nav')){
        Sticky.addOne(document.querySelector('.article__nav'));
    }
    
    $("table").wrap("<div class='table-responsive'></div>");

    if($(".roles").length && $(window).width() > 768 ){
        $(".roles").each(function(){
            var tabContent =  $(this).children(".tab-content");
            var tabPane = $(this).find(".tab-pane")
            
            $(tabPane).appendTo(tabContent);
            
        });
        
        $(".nav-link").click(function(){
            if ($(this).hasClass('active')){
                $('#' + this.hash.substr(1).toLowerCase()).toggleClass('active show');
            }
        });
        
    }
    /// hentet fra vfk 
     $(".timeline li").each(function() {

        $(this).wrapInner("<div class='timeline__section'>");
        $(this).find(".timeline__section ul").insertAfter($(this).find(".timeline__section"));

    });
    $(".to-top").click(function(e) {
        e.preventDefault();
        $("body,html").animate({ scrollTop: 0 }, 150);
    });

    function checkOffset() {
        var footerHeight = $(".footer").height()+ 55;
        if($('.to-top-footer').offset().top + $('.to-top-footer').height() >= $('.footer').offset().top + 10){
            $('.to-top-footer').addClass('position-absolute').removeClass("position-fixed");

            $(".to-top-footer").css('bottom', footerHeight);
        }
        if($(document).scrollTop() + window.innerHeight < $('.footer').offset().top){
            $('.to-top-footer').addClass("position-fixed").removeClass("position-absolute"); // restore when you scroll up
            $(".to-top-footer").css("bottom","");
            
        }
    }

    $(document).scroll(function() {
        checkOffset();
    });

    if($(".news-box").length){
        $(".news-box__title").dotdotdot({
            height: 70
        });
        $(".news-box__description").dotdotdot({
            height: 55
        });
    }
    if($(".school__news").length){
        // $(".news-box__title").dotdotdot({
        //     height: 70
        // });
        $(".school__news__description").dotdotdot({
            height: 85
        });
    }

    $("a[target='_blank']").attr("rel","noreferrer  noopener");
    




    /**
     * Take the first news article in the list and remove it. Since it's displayed in the top nyhet.
     * REMOVE THIS CODE WHEN WE FIGURE OUT HOW TO START THE NEWS LIST FROM INDEX 1.
     */
    if (document.querySelector('.all-news-list')) {
        document.querySelector('.all-news-list').remove();
    }

    
    /**
     * When the search form is submitted pass the input value on to Kari.
     */
    if (document.querySelector('.search__form')) {
        const form = document.querySelector('.search__form')
        form.addEventListener('submit', (e) => {
            e.preventDefault();

            var inputValue = e.target[0].value;

            window.chatbotCallback(inputValue);
        })
    }

    
    /**
     *
     */
    if (document.querySelector(".newslist")) {
        const fullNewsList = document.querySelector(".full-news-list");

        const getLocationHashNumber = () => {
            return window.location.hash.split("=")[1] || 0;
        }

        const shownewslistFrom = (newsnumber = 0) => {
            const news = document.querySelectorAll(".newslist .newslist__items--show-more");
            //const news = listContainer.querySelectorAll(".newslist__items--show-more");

            let n = 0;

            if (!document.getElementById("button-show-from") && news.length > 11) {
                fullNewsList.insertAdjacentHTML("beforeEnd", `
                    <div class="container mb-10 pb-7 mt-2 mt-lg-0 pb-lg-0 mt-lg-4 mb-lg-6">
                        <div class="row">
                            <div class="col-12 text-center">
                                <a class="btn flere-nyheter__anchor inline-block" href="#showfrom=${n+9}" id="button-show-from">
                                    Flere nyhetssaker
                                </a>
                            </div>
                        </div>
                    </div>
                `);
            }


            newsnumber = parseInt(newsnumber);
            newsnumber = newsnumber + 2;

            news.forEach(item => {
                (n >= (newsnumber + 9)) ? item.classList.add("d-none") : item.classList.add("d-block");

                n++;
            });
        }


        shownewslistFrom( getLocationHashNumber() );

        window.addEventListener('hashchange', () => {
            shownewslistFrom( getLocationHashNumber() );
        }, false);

        document.querySelector("#button-show-from").addEventListener("click", elm => {
            const btn = elm.target;

            const existingNumber = btn.getAttribute("href").split("=")[1];

            btn.setAttribute("href", "#showfrom=" + (parseInt(existingNumber) + 9));
        })

    }

});
